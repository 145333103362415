@charset "utf-8";

@use "@angular/material" as mat;
@use "./new-theme-base" as newTheme;

@include mat.core();

// read this if you have any questions about the theme: https://angular-material.dev/articles/angular-material-theming-system-complete-guide
@import "./theme-base";

@import url("https://fonts.googleapis.com/css2?family=Frank+Ruhl+Libre:wght@300..900&display=swap");

$platform-dark-blue: (
  50: #e8eaf6,
  100: #c5cbe9,
  200: #9fa8da,
  300: #7985cb,
  400: #5c6bc0,
  500: #3f51b5,
  600: #394aae,
  700: #3140a5,
  800: #29379d,
  900: #1b278d,
  A100: #c6cbff,
  A200: #939dff,
  A400: #606eff,
  A700: #4757ff,
  contrast: (
    50: #ffffff,
    100: #ffffff,
    200: #ffffff,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #ffffff,
    A700: #ffffff
  )
);

$primary: mat.define-palette($platform-dark-blue);
$accent: mat.define-palette(mat.$amber-palette, A200, A100, A400);
$warn: mat.define-palette(mat.$red-palette, A200, A100, A400);

$theme: mat.define-light-theme(
  (
    color: (
      primary: $primary,
      accent: $accent,
      warn: $warn,
      //  // Define custom colors for success and info
      //  success: mat.define-palette(mat.$green-palette),
      //  info: mat.define-palette(mat.$blue-palette),
    ),
    // typography: $custom-typography,
    density: 0
  )
);

$custom-colors: (
  footer-background-color: #f2f2f2,
  search-icon: mat.get-color-from-palette($primary, 50),
  search-button: mat.get-color-from-palette($primary, 500),
  search-button-hover: mat.get-color-from-palette($primary, 700),
  navbar-cart-wrapper: mat.get-color-from-palette($primary, 50),
  link-hover-color: #000000
);

$theme: map-merge(
  $theme,
  (
    custom-colors: $custom-colors
  )
);

@include mat.all-component-themes($theme);
@include newTheme.theme($theme);

// $primary: mat-palette($platform-dark-blue);
// $accent: mat-palette($mat-pink, A400, A200, A600);
// $warn: mat-palette($mat-orange, A400, A200, A600);

// // Change mat-dark-theme into mat-light-theme and your theme will use the lighter values of your palette.
// $theme: mat-light-theme($primary, $accent, $warn);

// // Custom colors
// $custom-colors: (
//     footer-background-color: #f2f2f2,
//     search-icon: mat-color($primary, 50),
//     search-button: mat-color($primary, 500),
//     search-button-hover: mat-color($primary, 700),
//     navbar-cart-wrapper: mat-color($primary, 500),
//     link-hover-color: #000000,
// );
// $theme: map-merge(
//     $theme,
//     (
//         custom-colors: $custom-colors,
//     )
// );

// @include theming($theme);

/**
* BULMA CSS
*/
// Set your brand colors
$purple: #8a4d76;
$pink: #fa7c91;
$brown: #757763;
$beige-light: #d0d1cd;
$beige-lighter: #eff0eb;
$navbar-color-variable: #5f9ea0;
$navbar-color-hover-variable: #589294;
$navbar-item-color-graphit: #555;

// Update Bulma's global variables
$grey-dark: $brown;
$grey-light: $beige-light;
$primary: $purple;
$link: $pink;
$family-sans-serif: "Frank Ruhl Libre", serif;
$navbar-background-color: white;
$navbar-item-hover-color: white;
$navbar-item-color: $navbar-item-color-graphit;
$navbar-item-hover-background-color: #3f51b5;
$navbar-dropdown-background-color: #3f51b5;
.navbar-end {
  background-color: white;
}
.form-sm {
  @include mat.form-field-density(-3);
}
// $widescreen-enabled: false;
// $fullhd-enabled: false;

// Update some of Bulma's component variables
$body-background-color: $beige-lighter;
$control-border-width: 2px;
$input-border-color: transparent;
$input-shadow: none;

// Import only what you need from Bulma
@import "node_modules/bulma/sass/utilities/_all.sass";
@import "node_modules/bulma/sass/elements/container.sass";
@import "node_modules/bulma/sass/elements/title.sass";
@import "node_modules/bulma/sass/form/_all.sass";
@import "node_modules/bulma/sass/components/navbar.sass";
@import "node_modules/bulma/sass/layout/section.sass";
